var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');

var AppRouter = Backbone.Router.extend({
  routes: {
    '*actions': 'defaultAction'
  },

  initialize: function(){
    this.listenTo(this, 'route', this._trackPageView);
  },

  _trackPageView: function(){
    var path = Backbone.history.getFragment();
    ga('send', 'pageview', {page: "/" + path});
  },

  defaultAction: function(){
    Aqualina.view.load();
  }
});

module.exports = AppRouter;
