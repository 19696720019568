var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var _basePage = require('./_basePage');
var imgSize = require('../utils/imgSize');

var Text = _basePage.fullExtend({

  initThis: function(){
    Aqualina.pubSub.on('app:resize', this.resizeThisPage, this);
    this.pubSub.on('page:removed', this.removeThisPage, this);

    this.resizeThisPage();
  },

  resizeThisPage: function(){
    if(this.$('.author .authorImage').length > 0){
      var $author = this.$('.author .authorImage');
      var _w = $author.outerWidth();
      imgSize({
        $el: $author.find('img'),
        width: _w + 2,
        minHeight: _w + 2,
        midX: true,
        midY: true
      });
    }
  },

  removeThisPage: function(){
    Aqualina.pubSub.off('app:resize', this.resizeThisPage);
  }

});

module.exports = Text;
