var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');

var pages = require('./pages');
var HeaderView = require('./components/header');
var BrochureOverlay = require('./components/overlayBrochure');

var breakpoints = require('./utils/breakpoints');

var AppView = Backbone.View.extend({

  el: 'body',

  events: {
    'click a': 'linkHandler',
    'click .brochureDownloadButton': 'brochureDownloadButtonHandler'
  },

  initialize: function(){
    _.bindAll(this, 'resize', 'scroll');
    $(window).on('resize', this.resize);
    $(window).on('scroll', this.scroll);

    Aqualina.pubSub.on({
      'app:resize:start': this.resizeStart,
      'app:resize:end': this.resizeEnd
    }, this);

    this.model = new Backbone.Model({
      'isTouch': 'ontouchstart' in document.documentElement,
      'isFirefox': navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
      'isMobile': false,
      'hasOverlay': false,
      'pageIsLoading': false
    });

    if(this.isTouch()){
      this.$el.addClass('isTouch');
    }

    if(this.isFirefox()){
      this.$el.addClass('isFirefox');
    }

    if(this.$('.brochureOverlayHtml').length > 0){
      var html = this.$('.brochureOverlayHtml').html();
      this.model.set('brochureOverlayHtml', html);
      this.$('.brochureOverlayHtml').remove();
    }

    this.model.on({
      'change:isResizing': this.isResizingToggle,
      'change:hasOverlay': this.hasOverlayToggle
    }, this);
  },

  isTouch: function(){
      return this.model.get('isTouch');
  },

  isFirefox: function(){
      return this.model.get('isFirefox');
  },

  isMobile: function(){
      return this.model.get('isMobile')
  },

  render: function(){
    this.headerView = new HeaderView();

    this.resize();
    this.scroll();
  },

  hasOverlayToggle: function(model, overlay){
    this.$el.toggleClass('hasOverlay', overlay);
  },

  load: function(page){
    var that = this;

    var href = this.currentUrl();
    if(this.headerView){
      this.headerView.delegateHeader();
    }

    if(!this.view){
      var $el = this.$('.page');
      if($el){
        this.view = this.delegatePage($el);
        this.view.animateIn(true);
        this.onLoaded();
      }

      setTimeout(function(){
        that.$el.addClass('isSet');
      }, 50);
    } else if(this.view.$('section[subpage-url="'+this.currentUrl()+'"]').length > 0){
      this.scrollToSubPageIfExists();
    } else {
      this.model.set('pageIsLoading', true);

      var promise, $content;

      var oldView = this.view;
      promise = oldView.animateOut();

      var ajax = $.ajax({
          url: '/'+Backbone.history.fragment,
          error: function (xhr, ajaxOptions, thrownError){
              var errorHref = window.location.protocol+'//'+window.location.hostname;
              if(window.location.port){
                  errorHref += ':'+window.location.port;
              }
              if(xhr.status == 404) {
                  window.location.replace(errorHref+'/'+Backbone.history.fragment);
              } else {
                  window.location.href = errorHref+'/'+Backbone.history.fragment;
              }
          },
          success: function(data, textStatus, xhr){
              if(_.isObject(xhr.responseJSON) && xhr.responseJSON.external_redirect){
                  window.location.replace(xhr.responseJSON.url);
                  return;
              }

              var $html = $('<div></div>').append(data);
              document.title = $html.find('title:eq(0)').text();
              $content = $(data).find('.page');
          }
      });

      $.when(promise).done(function(){
        oldView.removeView();
        $('body, html').scrollTop(0);
      });

      $.when(promise, ajax).done(function(){

        that.$('#container').html('');
        that.$('#container').append($content);

        var $el = that.$('.page');
        that.view = that.delegatePage($el);

        setTimeout(function(){
          that.view.animateIn();
          that.onLoaded();
          that.model.set('pageIsLoading', false);
        }, 10);
      });
    }
  },

  onLoaded: function(){
    this.scroll();
    if(!this.scrollToSubPageIfExists(true)){
      $('body, html').scrollTop(0);
    }
  },

  currentUrl: function(){
    return Aqualina.homeUrls[0]+Backbone.history.fragment;
  },

  delegatePage: function($el){
      var controller = $el.attr('view-controller');
      if(_.isUndefined(pages[controller])){
          controller = 'Default';
      }
      return new pages[controller]({el: $el});
  },

  linkHandler: function(e, _href){
      /*if ($el.hasClass('force-link') || (e && (e.metaKey || e.shiftKey || e.ctrlKey || e.altKey))){
        return;
      }*/
      var $el, href;

      if(this.model.get('pageIsLoading') && e){
        return;
      }

      if(!e && _href){
          href = _href;
      } else {
          $el = $(e.currentTarget);
          href = $el.attr('href');
          if($el.hasClass('translateLink')){
            return;
          }
      }

      var targetHref, internalUrl;

      _.each(Aqualina.homeUrls, function(_url){
          if(internalUrl){ return; }
          var strLength = _url.length;
          if(href.substr(0,strLength)==_url && (href.indexOf('wp-content')<0 && href.indexOf('uploads')<0)){
              targetHref = href.substr(strLength);
              internalUrl = true;
          }
      });
      if(internalUrl){
          if(e){
              e.preventDefault();
              e.stopPropagation();
          }
          this.navigateToPage(targetHref);
      }
  },

  navigateToPage: function(href){
      Aqualina.router.navigate(href, {trigger: true});
  },

  resize: _.throttle(function(){
      if(this.resizeTO){ clearTimeout(this.resizeTO); }
      if(!this.model.get('isResizing')){
          Aqualina.pubSub.trigger('app:resize:start');
      }

      this.w = $(window).width();
      this.h = $(window).height();

      this.model.set('isMobile', this.w <= breakpoints.mobile);

      this.cW = Math.min(this.w, 1680) - (this.getMargin() * 2);

      this.resizeTO = setTimeout(function(){
          Aqualina.pubSub.trigger('app:resize');
          Aqualina.pubSub.trigger('app:resize:end');
      }, 250);
      Aqualina.pubSub.trigger('app:resize');
  }, 16),

  resizeStart: function(){
      this.model.set('isResizing', true);
  },

  resizeEnd: function(){
      this.model.set('isResizing', false);
  },

  isResizingToggle: function(model, resizing){
      this.$el.toggleClass('isResizing', resizing);
  },

  resizeScroll: function(){
    this.w = $(window).width();
    this.h = $(window).height();
    Aqualina.pubSub.trigger('app:resize:scroll');
  },

  scroll: _.throttle(function(){
    var scrollPos = $(window).scrollTop();

    this.scrollPosition = scrollPos;
    Aqualina.pubSub.trigger('app:scroll');
  }, 16),

  scrollbarWidth: function(){
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar";

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    outer.style.overflow = "scroll";

    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
  },

  scrollTo: function($el, jump){
    var time = 1200;
    if(jump){
      if(this.$el.hasClass('isSet')){
        time = 50;
      } else {
        time = 150;
      }
    }

    if($el.length <= 0){
      return;
    }

    var position = $el.offset().top;
    position -= this.getHeaderHeight();

    $('body, html').animate({
      scrollTop: position
    }, time);
  },

  scrollToSubPageIfExists: function(jump){
    var $el = this.view.$('section[subpage-url="'+this.currentUrl()+'"]');
    if($el.length > 0){
      this.scrollTo($el, jump);
      return true;
    } else {
      return false;
    }
  },

  getVisibleHeight: function(){
    return this.h - this.getFooterHeight() - this.getHeaderHeight();
  },

  getHeaderHeight: function(){
    return this.headerView.returnHeight();
  },

  getFooterHeight: function(){
    return 50;
  },

  getMargin: function(){
    var w = 40;
    if(Aqualina.view.w <= breakpoints.mobile){
      w = 20;
    } else if(Aqualina.view.w <= breakpoints.tabletPortrait){
      w = 30;
    }
    return w;
  },

  brochureDownloadButtonHandler: function(e){
    e.preventDefault();
    e.stopPropagation();

    var html = this.model.get('brochureOverlayHtml');
    var overlay = new BrochureOverlay({brochureHtml: html});

    this.view.overlayAdd(overlay);
  }
});

module.exports = AppView;
