var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var imgSize = require('../utils/imgSize');

var overlayBase = require('./overlayBase');

var OverlayShare = overlayBase.fullExtend({

  attrName: 'share',

  contentTemplate: _.template('<div class="popBox">\
    <div class="closeBttn"></div>\
    <h3>Share</h3>\
    <div class="links">\
      <a href="https://twitter.com/share?url=<%= url %>&text=<%= title %>" target="_blank" class="socialIcon socialIconTwitter"></a>\
      <a href="https://www.facebook.com/sharer/sharer.php?u=<%= url %>" target="_blank" class="socialIcon socialIconFacebook"></a>\
      <% if(image){ %><a href="https://www.pinterest.com/pin/create/button/?url=<%= url %>&media=<%= image %>&description=<%= title %>" target="_blank"  class="socialIcon socialIconPinterest"></a><% } %>\
      <a href="https://plus.google.com/share?url=<%= url %>" target="_blank" class="socialIcon socialIconGoogle"></a>\
    </div>\
    <div class="url">\
      <span>Copy URL</span>\
      <input type="text" value="<%= url %>" />\
    </div>\
  </div>'),

  initThis: function(opts){
    var url = opts.url;
    var image = opts.image;
    var title = opts.title;
    if(!url){
        url = window.location.href;
    }
    if(!title){
        title = 'The Estates at Acqualina';
    }
    this.model.set({
        title: title,
        image: image,
        url: url,
    });
  },

  renderThis: function(){
    var html = this.contentTemplate(this.model.toJSON());
    return html;
  }
});

module.exports = OverlayShare;
