var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var videojs = require('video.js');

var breakpoints = require('../utils/breakpoints');
var imgSize = require('../utils/imgSize');

var VideoView = Backbone.View.extend({
    events: {},

    initialize: function(opts){
        _.bindAll(this, 'loadedVideo', 'endVideo');
        var that = this;

        this.pubSub = _.extend({}, Backbone.Events);

        if(this.showFallbackGIF()){
          this.$('video').remove();
          var $gifContainer = this.$('.fallbackGIF');
          $gifContainer.append('<img src="'+$gifContainer.attr('img-src')+'" width="'+$gifContainer.attr('img-width')+'" height="'+$gifContainer.attr('img-height')+'" />');
          this.resizeVideo();
        } else {
          if(this.$('video').length <= 0){ return; }

          if(this.$el.hasClass('videoBlockHasFallbackGIF')){
            this.$('.fallbackGIF').remove();
          }

          var vjsOpts = {};

          var $video = that.$('video');

          videojs(this.$('video')[0], vjsOpts, function(){
              that.vjs = this;
              var $video = that.$('video');

              if($video[0].readyState < 2){
                  this.on('loadedmetadata', that.loadedVideo, that);
              } else {
                  that.loadedVideo();
              }

              if($video.attr('autoplay') || opts.autoplay){
                  this.play();
              }

              this.on('timeupdate', that.endVideo, that);

              that.isMobileToggle(Aqualina.view.model, Aqualina.view.model.get('isMobile'));
          });
        }

        Aqualina.pubSub.on('app:resize', this.resizeVideo, this);

        this.listenTo(Aqualina.view.model, {
          'change:isMobile': this.isMobileToggle
        }, this);
    },

    isMobileToggle: function(model, mobile){
        var $video = this.$('video');

        if($video.attr('mobile-poster')){
            var poster;
            if(mobile){
                poster = $video.attr('mobile-poster');
            } else {
                poster = $video.attr('poster');
            }
            this.vjs.poster(poster);
        }
    },

    loadedVideo: function(){
        this.$el.addClass('videoLoaded');
        this.resizeVideo();

        Aqualina.pubSub.trigger('app:resize');
    },

    endVideo: function(){
      var t = this.vjs.currentTime();

      if(_.isFinite(this.pTime) && t < this.pTime){
        this.pubSub.trigger('video:end');
      }
      this.pTime = t;
    },

    resizeVideo: function(){
      var width = this.$el.outerWidth();
      var height = this.$el.outerHeight();

      if(this.showFallbackGIF()){
        var $gifContainer = this.$('.fallbackGIF');
        var dimensions = imgSize({
          $el: $gifContainer,
          imgW: parseInt($gifContainer.attr('img-width')),
          imgH: parseInt($gifContainer.attr('img-height')),
          width: width,
          minHeight: height,
          midX: true,
          midY: true
        });

        if(this.$el.hasClass('videoBlockInline')){
          this.$el.css({'height': dimensions.height});
        }
      } else {
        var $video = this.$('video');
        if($video.length <= 0 || !this.$el.hasClass('videoLoaded')){ return; }

        var videoWidth = $video[0].videoWidth;
        var videoHeight = $video[0].videoHeight;

        var opts = {
            $el: this.$('div:eq(0)'),
            imgW: videoWidth,
            imgH: videoHeight,
            width: width,
            midX: true,
            midY: true
        };
        if(this.$el.hasClass('videoBlockOverlay')){
          opts['maxHeight'] = height;
        } else if(!this.$el.hasClass('videoBlockInline')){
            opts['minHeight'] = height;
        }

        var dimensions = imgSize(opts);

        if(this.$el.hasClass('videoBlockInline')){
            this.$el.css({'height': dimensions.height});
        }
      }
    },

    showFallbackGIF: function(){
      return (this.$el.hasClass('videoBlockHasFallbackGIF') && Aqualina.view.isTouch())
        || (this.$el.hasClass('videoBlockHasFallbackGIFResponsive') && Aqualina.view.isMobile());
    },

    removeView: function(){
        Aqualina.pubSub.off('app:resize', this.resizeVideo);

        if(this.vjs){
          this.vjs.dispose();
        }

        this.stopListening();
        this.undelegateEvents();
        this.$el.remove();

        this.remove();
    }
});

module.exports = VideoView;
