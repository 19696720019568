var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var _baseChild = require('./_baseChild');
var imgSize = require('../../utils/imgSize');
var breakpoints = require('../../utils/breakpoints');

var Gallery = _baseChild.fullExtend({

  initThis: function(){
    this.initThisThis();
  },

  initThisThis: function(){},

  setChildHeight: function(){
    return true;
  },

  getChildHeight: function(){
    var h = Aqualina.view.w * this.getChildRatio();
    h += 40;
    return h;
  }

});

module.exports = Gallery;
