var $ = require('jquery');
var Backbone = require('backbone');
var _ = require('underscore');

var AppView = require('./appView');
var Router = require('./router');

var Aqualina = {};

Aqualina.pubSub = _.extend({}, Backbone.Events);

$(document).ready(function(){
  Aqualina.view = new AppView();
  Aqualina.view.render();
  Aqualina.router = new Router();
  Backbone.history.start({pushState: true, hashChange: false, root: '/'});
});

global.Aqualina = Aqualina;
