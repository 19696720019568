var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');

var imgSize = require('../utils/imgSize');
var preloadImages = require('../utils/preloadImages');

var VideoView = require('./video');

var ImageView = Backbone.View.extend({
    className: 'image isLoading imgOut',

    template: _.template('<img src="<%= src %>" width="<%= width %>" height="<%= height %>"/>'),
    videoTemplate: _.template('<div class="videoBlock inGallery <% if(video_gif){ %>videoBlockHasFallbackGIF videoBlockHasFallbackGIFResponsive<% } %>">\
      <% if(video_gif){ %>\
        <div img-src="<%= video_gif %>" img-width="<%= width %>" img-height="<%= height %>" class="fallbackGIF"></div>\
      <% } %>\
      <video width="<%= width %>" height="<%= height %>" poster="<%= src %>" tabindex="0" preload="auto" autoplay loop>\
        <% if(video_mp4){ %><source type="video/mp4" src="<%= video_mp4 %>"><% } %>\
        <% if(video_webm){ %><source type="video/webm" src="<%= video_webm %>"><% } %>\
      </video>\
    </div>'),

    initialize: function(opts){
        var that = this;
        this.parent = opts.parent;
        this.model = opts.model;

        var src = this.model.get('src');
        preloadImages([src]).done(function(){
            that.$el.removeClass('isLoading');
        });
        Aqualina.pubSub.on('app:resize', this.resize, this);
    },

    render: function(){
        var template = this.template;
        if(this.model.get('video')){
          template = this.videoTemplate;
        }
        this.$el.html(template(this.model.toJSON()));
        return this;
    },

    postRender: function(){
      if(this.model.get('video')){
        this.videoView = new VideoView({el: this.$('.videoBlock')});
      }
    },

    resize: function(){

      if(this.model.get('video')){ return; }
        var $el = this.$('img');

        var opts = _.extend({}, this.returnImageOptions($el), {
            $el: $el
        });

        imgSize(opts);
    },

    returnImageOptions: function($img){
        var opts = {};
        var w = $img.attr('width');
        var h = $img.attr('height');

        opts.imgW = w;
        opts.imgH = h;
        opts.width = Aqualina.view.w;

        return opts;
    },

    removeView: function(){
      if(this.videoView){
        this.videoView.removeView();
      }

        this.undelegateEvents();
        Aqualina.pubSub.off('app:resize', this.resize, this);
        this.$el.remove();

        this.remove();
    }

});

module.exports = ImageView
