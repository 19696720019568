var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../../utils/Backbone.fullExtend');

var imgSize = require('../../utils/imgSize');
var breakpoints = require('../../utils/breakpoints');

var ChildView = Backbone.View.extend({
  events: {},

  initialize: function(opts){
    var that = this;

    this.parent = opts.parent;

    this.model = new Backbone.Model({
      active: false,
      visible: false,
      percScrolled: 0
    });
    this.pubSub = _.extend({}, Backbone.Events);

    this.model.on({
      'change:active': this.activeToggle,
      'change:visible': this.visibleToggle
    }, this);

    Aqualina.pubSub.on('app:resize', this.resizeThis, this);
    Aqualina.pubSub.on('app:scroll', this.scrollThis, this);
    this.initThis();
    this.resizeThis();
  },

  initThis: function(){
    return false;
  },

  initThisEnd: function(){
    return false;
  },

  resizeThis: function(){
    this.$el.css({'height': ''});

    var height = this.getChildHeight();

    if(this.setChildHeight()){
      this.$el.css({'height': height});
    }

    this.$('.bg').each(function(){
      var $img = $('img', this);

      if($img.length > 0){
        var opts = {
          $el: $img,
          midX: true,
          midY: true,
          width: Aqualina.view.w
        };

        if($(this).hasClass('fill')){
          opts.minHeight = height;
        } else {
          opts.maxHeight = height;
        }

        imgSize(opts);
      }
    });
  },

  scrollThis: function(){
    var percIn = 1;
    var percToScroll = 0.75;
    var percToActive = 0.6;
    var scrollTop = Aqualina.view.scrollPosition;
    var offset = this.$el.offset().top;
    var height = this.getChildHeight();

    if(this.trackPercScrolled()){
      var percScrolled = Math.max(0, (scrollTop + Aqualina.view.h - offset - (Aqualina.view.getFooterHeight() * 1.2)) / ((Aqualina.view.h - Aqualina.view.getFooterHeight())  * percIn));
      percScrolled = percScrolled / percToScroll;
      percScrolled = Math.min(percScrolled, 1);
      this.model.set('percScrolled', percScrolled);
    }

    this.model.set('visible', (offset < scrollTop + Aqualina.view.h) && offset + height > scrollTop);
    this.model.set('active', (offset < scrollTop + (Aqualina.view.h * percToActive)) && this.model.get('visible') && (!this.trackPercScrolled() || this.model.get('percScrolled') >= percToScroll));
  },

  getChildHeight: function(){
    var h;

    if(this.setChildHeight()){
      h = Aqualina.view.w * this.getChildRatio();
      if(this.limitHeightToWindow()){
        h = Math.min(Aqualina.view.getVisibleHeight(), h);
      }
    } else {
      h = this.$el.outerHeight();
    }
    return h;
  },

  limitHeightToWindow: function(){
    return false;
  },

  getChildRatio: function(){
    return 9 / 16;
  },

  setChildHeight: function(){
    return false;
  },

  trackPercScrolled: function(){
    return false;
  },

  activeToggle: function(model, active){
    this.$el.toggleClass('active', active);
    if(active){
      this.pubSub.trigger('active:in');
    } else {
      this.pubSub.trigger('active:out');
    }
  },

  visibleToggle: function(model, visible){
    this.$el.toggleClass('visible', visible);
    if(visible){
      this.pubSub.trigger('visible:in');
    } else {
      this.pubSub.trigger('visible:out');
    }
  },

  removeView: function(){
    Aqualina.pubSub.off('app:resize', this.resizeThis);
    Aqualina.pubSub.off('app:scroll', this.scrollThis);
    this.pubSub.trigger('section:removed');
    this.undelegateEvents();
    this.$el.remove();

    this.remove();
  }
});

module.exports = ChildView;
