var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');
var Velocity = require('velocity-animate');

var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');
var preloadImages = require('../utils/preloadImages');

var GalleryInlineView = require('../components/gallery_inline');
var VideoView = require('../components/video');
var GridView = require('../components/grid');
var children = require('../components/sections');
var ShareOverlay = require('../components/overlayShare');
var VideoOverlay = require('../components/overlayVideo');
var ImageOverlay = require('../components/overlayImage');

var PageView = Backbone.View.extend({
  events: {
    'click .shareButton': 'shareHandler',
    'click .videoBlockExpand': 'videoBlockExpandHandler',
    'click .imageBlockExpand': 'imageBlockExpandHandler'
  },

  initialize: function(){
    var that = this;

    this.model = new Backbone.Model();

    this.pubSub = _.extend({}, Backbone.Events);
    this.pubSub.on('page:loaded', this.pageLoaded, this);

    Aqualina.pubSub.on('app:resize', this.resize, this);

    this.children = [];
    this.$('section').each(function(){
      that.children.push(that.delegateChild($(this)));
    });

    this.galleriesInline = [];
    this.$('.galleryInlineBuild').each(function(){
      var $scope = $('.galleryScope', this);
      var array = that.buildGalleryArray($scope);
      if(array.length > 0){
        var opts = {
          data: array,
          parent: that,
          start: 0,
          infinite: true,
          breadcrumbs: true
        };

        var view = new GalleryInlineView(opts);
        $(this).append(view.render().$el);

        $scope.remove();
        that.galleriesInline.push(view);
      } else {
        $scope.remove();
      }
    });

    this.videos = [];
    this.$('.videoBlock').each(function(){
      if($(this).hasClass('inGallery')){ return; }
      that.videos.push(new VideoView({el: $(this)}));
    });

    this.grids = [];
    this.$('.grid').each(function(){
      that.grids.push(new GridView({el: $(this)}));
    });

    this.preloadImages();
    this.initThis();
    this.resize();

    _.each(this.galleriesInline, function(_gallery){
      _gallery.forceResize();
    });

    _.each(this.children, function(_child){
      _child.view.initThisEnd();
    });
  },

  initThis: function(){
      return false;
  },

  delegateChild: function($el){
    var target = $el.attr('view-controller');
    var controller = target;
    if(_.isUndefined(children[controller])){
      controller = 'Default';
    }
    return {
      target: target,
      view: new children[controller]({el: $el, parent: this})
    }
  },

  preloadImages: function(){
    var that = this;
    this.$('.imageBlock').each(function(){
      that.preloadImageBlock($(this));
    });
  },

  preloadImageBlock: function($imageBlock){
    var $el = $imageBlock;
    var imgSrc =  $el.find('img').attr('src');
    if(imgSrc){
      preloadImages([imgSrc]).done(function(){
        if($el.length > 0){
          $el.addClass('isLoaded');
        }
      });
    }
  },

  resize: function(){
    this.$('.imageBlock.imageBlockInline').each(function(){
      var $el = $(this);
      var width = $el.outerWidth();

      var dimensions = imgSize({
        $el: $el.find('img'),
        width: width + 2,
        midX: true,
        midY: true
      });

      $el.css({'height': dimensions.height - 2});
    });

    this.$('.videoBlockExpand.videoBlockExpandInline').each(function(){
      var $el = $(this);
      var width = $el.outerWidth();
      var setHeight = false;

      var opts = {
        $el: $el.find('img'),
        width: width + 2,
        midX: true,
        midY: true
      };

      if($el.hasClass('videoBlockExpandSet')){
        var _minHeight = width / 16 * 9;
        if($el.hasClass('videoBlockExpandSetHalf')){
          _minHeight *= 0.5;
        }
        opts['minHeight'] = _minHeight;
        setHeight = _minHeight;
      }

      var dimensions = imgSize(opts);

      if(!setHeight){
        setHeight = dimensions.height - 2;
      }

      $el.css({'height': setHeight});
    });
  },

  animateIn: function(){
    var that = this;
    var promise = $.Deferred();

    this.$el.addClass('viewIn');
    setTimeout(function(){
      promise.resolve();
      that.pubSub.trigger('page:loaded');
    }, 800);
    return promise.promise();
  },

  animateOut: function(){
    var promise = $.Deferred();

    this.pubSub.trigger('page:removing');
    this.$el.removeClass('viewIn pageLoaded');
    setTimeout(function(){
      promise.resolve();
    }, 800);
    return promise.promise();
  },

  pageLoaded: function(){
    this.$el.addClass('pageLoaded');
  },

  getGalleryScope: function($el){
    var $scope = $el.closest('.galleryScope');
    if(!$scope){
      if($el.hasClass('galleryScope')){
        $scope = $el;
      } else {
        $scope = this.$el;
      }
    }
    return $scope;
  },

  getGalleryIndex: function($scope, $e){
    var index;
    index = $scope.find(this.getGallerySelector()).index($e);
    return index;
  },

  getGallerySelector: function(){
      return '.galleryObj';
  },

  buildGalleryArray: function($scope){
    var that = this;
    var array = [];

    $scope.find(this.getGallerySelector()).each(function(){
      var $obj = $(this);
      var opts = {
        id:         parseInt($obj.attr('img-id')),
        src:        $obj.attr('img-src'),
        width:      parseInt($obj.attr('img-width')),
        height:     parseInt($obj.attr('img-height')),
        caption:    $obj.attr('img-caption')
      };

      if($obj.attr('img-video')){
        opts['video'] = true;
        opts['video_mp4'] = $obj.attr('video-mpfour');
        opts['video_webm'] = $obj.attr('video-webm');
        opts['video_gif'] = $obj.attr('video-gif');
      }

      array.push(opts);
    });
    return array;
  },

  buildCarouselCaptionForOverlay: function(){
    return false;
  },

  overlayAdd: function(view){
    this.removeOverlays();
    view.setParent(this);
    Aqualina.view.$el.append(view.render().$el);
    view.animateIn();
    this.overlay = view;
  },

  removeOverlays: function(){
    if(this.overlay){
      this.overlay.animateOut(true);
      this.overlay = null;
    }
  },

  shareHandler: function(e){
    e.preventDefault();
    e.stopPropagation();

    var $e = $(e.currentTarget);

    var opts = {};
    if($e.attr('share-image')){ opts.image = $e.attr('share-image'); }
    if($e.attr('share-url')){ opts.url = $e.attr('share-url'); }
    if($e.attr('share-title')){ opts.title = $e.attr('share-title'); }
    var view = new ShareOverlay(opts);
    this.overlayAdd(view);
  },

  videoBlockExpandHandler: function(e){
    e.preventDefault();
    e.stopPropagation();

    var $e = $(e.currentTarget);

    var hasVideo = false;
    var opts = {};

    var $video = $e.find('video');
    if($video.length > 0){
      hasVideo = true;
      opts['videoHtml'] = $('<div></div>').append($video.clone()).html();
    } else {
      var $videoObj = $e.find('.videoObj');
      if($videoObj.length > 0){
        opts['videoHtml'] = this.videoBlockTemplate({
          width:    $videoObj.attr('data-width'),
          height:   $videoObj.attr('data-height'),
          poster:   $videoObj.attr('data-poster'),
          mp4:      $videoObj.attr('data-mpfour'),
          webm:     $videoObj.attr('data-webm')
        });
        hasVideo = true;
      }
    }
    if(!hasVideo){ return; }

    var view = new VideoOverlay(opts);

    this.overlayAdd(view);
  },

  videoBlockTemplate: _.template('\
    <video width="<%= width %>" height="<%= height %>" poster="<%= poster %>" tabindex="0" preload="metadata" controls>\
      <% if(mp4){ %><source type="video/mp4" src="<%= mp4 %>"><% } %>\
      <% if(webm){ %><source type="video/webm" src="<%= webm %>"><% } %>\
    </video>'),

  imageBlockExpandHandler: function(e){
    e.preventDefault();
    e.stopPropagation();

    var $e = $(e.currentTarget);

    var src, width, height;
    var $imgLrg = $e.find('.imgLrg');

    var view = new ImageOverlay({
      src: $imgLrg.attr('img-src'),
      width: parseInt($imgLrg.attr('img-width')),
      height: parseInt($imgLrg.attr('img-height')),
      caption: $imgLrg.attr('img-caption')
    });

    this.overlayAdd(view);
  },

  removeView: function(){
    this.removeOverlays();

    _.each([this.galleriesInline, this.videos, this.grids], function(_collection){
      _.each(_collection, function(_view){
        _view.removeView();
      })
    });

    _.each(this.children, function(_child){
      _child.view.removeView();
    });

    Aqualina.pubSub.off('app:resize', this.resize);
    this.pubSub.trigger('page:removed');

    this.undelegateEvents();
    this.$el.remove();

    this.remove();
  }
});

module.exports = PageView;
