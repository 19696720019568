var Children = {};

Children.Default = require('./_baseChild');
Children.LandingImage = require('./LandingImage');
Children.Designer = require('./Designer');
Children.Gallery = require('./Gallery');
Children.GallerySlideIn = require('./GallerySlideIn');
Children.AmenitiesMap = require('./AmenitiesMap');
Children.HomepageBlocks = require('./HomepageBlocks');

module.exports = Children;
