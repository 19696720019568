var Pages = {};

Pages.Default = require('./_basePage');
Pages.Homepage = require('./Home');
Pages.Sales = require('./Sales');
Pages.Signup = require('./Signup');
Pages.Text = require('./Text');
Pages.Floorplans = require('./Floorplans');
Pages.Floorplan = require('./Floorplan');

module.exports = Pages;
