var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');
require('form-serializer');

var Sales = require('./Sales');

var Signup = Sales.fullExtend({

    getDataForForm: function($form){
      return $form.serializeObject();
    },

    getFormAction: function(){
      return 'mailchimp_signup';
    },

    formSubmissionGAEvent: function(){
      ga('send', 'event', 'signup form', 'signup');
    }
});

module.exports = Signup;
