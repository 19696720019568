var breakpoints = {
	desktop: 1440,
  laptop: 1280,
	tablet: 1024,
	tabletPortrait: 820,
	mobile: 640,
	mobilePortrait: 480
}

module.exports = breakpoints;
