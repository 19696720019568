var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var baseImageView = require('./galleryImage_base');

var breakpoints = require('../utils/breakpoints');

var ImageView = baseImageView.fullExtend({
    returnImageOptions: function($img){
        var opts = {};
        var w = parseInt($img.attr('width'));
        var h = parseInt($img.attr('height'));

        opts.imgW = w;
        opts.imgH = h;
        opts.midX = true;
        opts.midY = true;

        opts.width = this.parent.model.get('imgW');
        opts.minHeight = this.parent.model.get('imgH') - 40;

        return opts;
    }
});

module.exports = ImageView
