var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var _baseChild = require('./_baseChild');
var imgSize = require('../../utils/imgSize');
var breakpoints = require('../../utils/breakpoints');

var AmenitiesMap = _baseChild.fullExtend({

  events: {
    'mouseenter .amenity': 'amenityInHandler',
    'mouseleave .amenity': 'amenityOutHandler',
    'click .amenityMarker': 'amenityMarkerTouchHandler',
    'click .amenitiesMap': 'amenitiesMapTouchHandler'
  },

  initThis: function(){
    Aqualina.pubSub.on('app:resize', this.resizeThisSection, this);
    this.pubSub.on('section:removed', this.removeThisSection, this);

    this.resizeThisSection();
  },

  resizeThisSection: function(){
    imgSize({
      $el: this.$('.amenitiesMapCont .amenitiesMap img'),
      width: Aqualina.view.w + 1,
      minHeight: this.getChildHeight() + 1,
      midX: true,
      midY: true
    });
  },

  getChildHeight: function(){
    var h;

    if(this.setChildHeight() && Aqualina.view.w > breakpoints.mobile){
      h = Aqualina.view.w * this.getChildRatio();
    } else {
      h = this.$el.outerHeight();
    }

    return h;
  },

  getChildRatio: function(){
    var w, h;

    var $img = this.$('.amenitiesMapCont .amenitiesMap img');
    w = parseInt($img.attr('width'));
    h = parseInt($img.attr('height'));

    if(!_.isFinite(w)){ w = 16; }
    if(!_.isFinite(h)){ h = 9; }
    return h / w;
  },

  setChildHeight: function(){
    return true;
  },

  clearAmenityOutTO: function(){
    if(this.amenityOutTO){ clearTimeout(this.amenityOutTO); }
  },

  amenityOutHandler: function(e){
    if(Aqualina.view.isTouch()){ return; }

    e.preventDefault();
    e.stopPropagation();

    var $e = $(e.currentTarget);
    this.amenityOut($e);
  },

  amenityInHandler: function(e){
    if(Aqualina.view.isTouch()){ return; }

    e.preventDefault();
    e.stopPropagation();

    var $e = $(e.currentTarget);
    this.amenityIn($e);
  },

  amenityMarkerTouchHandler: function(e){
    if(!Aqualina.view.isTouch()){ return; }

    e.preventDefault();
    e.stopPropagation();

    var $e = $(e.currentTarget);
    var $amenity = $e.closest('.amenity');

    this.amenityIn($amenity);
  },

  amenitiesMapTouchHandler: function(e){
    if(!Aqualina.view.isTouch()){ return; }

    e.preventDefault();
    e.stopPropagation();

    this.$('.amenitiesMapCont .amenity.mouseactive').removeClass('mouseactive');
    this.resetAmenities();
  },

  amenityIn: function($el){
    this.clearAmenityOutTO();
    this.$('.amenitiesMapCont .amenity.mouseactive').removeClass('mouseactive');

    var $amenity = $el;
    $amenity.addClass('mouseactive');

    this.resetAmenities();

    var active = $amenity.hasClass('active');

    if(!active){
      this.$('.amenitiesMapCont .amenity').addClass('inactive');
      $amenity.removeClass('inactive').addClass('active');
      this.fadeInAmenity($amenity);
    }
  },

  amenityOut: function($el){
    this.clearAmenityOutTO();

    var that = this;

    var $amenity = $el;

    this.amenityOutTO = setTimeout(function(){
      $amenity.removeClass('mouseactive');
      that.resetAmenities();
    }, 250);
  },

  resetAmenities: function(){
    var that = this;
    this.$('.amenitiesMapCont .amenity.active:not(.mouseactive)').each(function(){
      that.fadeOutAmenity($(this));
    });
    this.$('.amenitiesMapCont .amenity').removeClass('inactive');
  },

  fadeInAmenity: function($el){
    var that = this;
    $el.addClass('fading');
    $el.removeClass('hasSet');

    Velocity($el.find('.amenityPop'), {
      opacity: 1
    }, {
      progress: function(el){
        if(!$el.hasClass('hasSet')){
          that.resizeAmenity($el);
          that.playAmenity($el);
          $el.addClass('hasSet');
        }
      },
      display: 'block',
      duration: 600,
      queue: false,
      complete: function(el){
        $el.removeClass('fading');
        that.resizeAmenity($el);
      }
    });
  },

  fadeOutAmenity: function($el){
    var that = this;

    this.pauseAmenity($el);

    $el.addClass('fading');
    Velocity($el.find('.amenityPop'), {
      opacity: 0
    }, {
      display: 'none',
      duration: 300,
      queue: false,
      complete: function(el){
        $el.removeClass('fading hasSet active');
      }
    });
  },

  playAmenity: function($el){
    var $video = $el.find('.videoBlock');
    if($video.length > 0){
      _.each(this.parent.videos, function(_video){
        if(_video.$el.closest('.amenity').hasClass('active') && _video.vjs){
          _video.vjs.play();
        }
      });
    }
  },

  pauseAmenity: function($el){
    var $video = $el.find('.videoBlock');
    if($video.length > 0){
      _.each(this.parent.videos, function(_video){
        if(_video.$el.closest('.amenity').hasClass('active') && _video.vjs){
          _video.vjs.pause();
        }
      });
    }
  },

  resizeAmenity: function($el){
    var $pop = $el.find('.amenityPop');

    $pop.css({'left': ''});

    var left = $el.offset().left;
    var newLeft = null;
    var margin = 20;
    var width = $pop.outerWidth();
    var offset = (width / 2);

    if(left + (offset * -1) < margin){
      newLeft = margin - left;
    } else if(left + offset > Aqualina.view.w - margin){
      newLeft = (Aqualina.view.w - margin) - left - width;
    }

    if(_.isFinite(newLeft)){
      $pop.css({'left': newLeft});
    }

    var $img = $el.find('.imageBlock');
    if($img.length > 0){
      var w = $img.outerWidth();
      var dimensions = imgSize({
        $el: $img.find('img'),
        width: w,
        midX: true,
        midY: true
      });

      $img.css({'height': dimensions.height});
    }

    var $video = $el.find('.videoBlock');
    if($video.length > 0){
      _.each(this.parent.videos, function(_video){
        if(_video.$el.closest('.amenity').hasClass('active')){
          _video.resizeVideo();
        }
      });
    }
  },

  removeThisSection: function(){
    Aqualina.pubSub.off('app:resize', this.resizeThisSection);
  }

});

module.exports = AmenitiesMap;
