var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');

var _basePage = require('./_basePage');

var Sales = _basePage.fullExtend({

    events: {
        'submit form': 'formSubmit'
    },

    formSubmit: function(e){
        var that = this;
        if(this.isSubmitting){ return; }
        this.setSubmitting();

        var errors = [];

        this.removeErrors();

        this.$('.toValidate').each(function(){
            var $input = $('input, textarea', this).first();
            var validate = that.validateInput($input);
            if(validate.error){
                errors.push({
                    $el: $(this),
                    message: validate.message
                });
            }
        });

        if(errors.length > 0){
            e.preventDefault();
            e.stopPropagation();

            this.displayErrors(errors);
            this.unsetSubmitting();

            Aqualina.view.scrollTo(this.$('form'));
        } else {
            this.formSubmission(e);
        }
    },

    setSubmitting: function(){
      var $submitButton = this.$('input[type="submit"]');
      $submitButton.prop('disabled', true);
      this.isSubmitting = true;
    },

    unsetSubmitting: function(){
      var $submitButton = this.$('input[type="submit"]');
      $submitButton.prop('disabled', false);
      this.isSubmitting = false;
    },

    removeErrors: function(){
        this.$('.toValidate.hasError').each(function(){
            $('.error', this).remove();
            $(this).removeClass('hasError');
        });
        this.resize();
    },

    displayErrors: function(errors){
        _.each(errors, function(_error){
            _error.$el.addClass('hasError').append('<div class="error">'+_error.message+'</div>');
        });
        this.resize();
    },

    formSubmission: function(e){
      var that = this;

      e.preventDefault();

      var $form = $(e.currentTarget);
      var $submitButton = this.$('input[type="submit"]');

      this.submissionDefault = $submitButton.val();
      $submitButton.val('Submitting...');

      var data = this.getDataForForm($form);
      $.ajax({
        method: 'POST',
        url: Aqualina.ajaxUrl + '?action=' + this.getFormAction(),
        data: data,
        success: function(data){
          that.formSubmissionSuccess();
        }
      });
    },

    getDataForForm: function($form){
      return {
        form_elements: $form.serializeArray()
      }
    },

    getFormAction: function(){
      return 'sales_inquiry';
    },

    formSubmissionSuccess: function(){
      var that = this;

      this.formSubmissionGAEvent();

      var $submitButton = this.$('input[type="submit"]');
      this.$('input:not([type="submit"]), textarea').val('');
      $submitButton.val('Thank you');
      this.$('form').blur();
      setTimeout(function(){
        that.formSubmissionUndo();
      }, 3000);
    },

    formSubmissionGAEvent: function(){
      ga('send', 'event', 'contact form', 'sales inquiry');
    },

    formSubmissionUndo: function(){
      var $submitButton = this.$('input[type="submit"]');
      $submitButton.val('Submit');
      this.unsetSubmitting();
    },

    validateInput: function($e){
        var val = $e.val();
        var type = $e.attr('type');
        var valid = false;
        var message = '';

        if(type == 'email'){
            var regex = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
            if(regex.test(val)){
                valid = true;
            } else {
                message = 'Invalid';
            }
        } else {
            if(val && val.length > 0){
                valid = true;
            } else {
                message = 'Required';
            }
        }

        return {
            error: !valid,
            message: message
        };
    }
});

module.exports = Sales;
