var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var imgSize = require('../utils/imgSize');

var overlayBase = require('./overlayBase');

var OverlayImage = overlayBase.fullExtend({

  attrName: 'image',

  contentTemplate: _.template('<div class="captionBar"><% if(caption){ %><p><%= caption %></p><% } %></div>\
    <img src="<%= src %>" width="<%= width %>" height="<%= height %>"/>'),

  initThis: function(opts){
    Aqualina.pubSub.on('app:resize', this.resizeImage, this);
    this.pubSub.on('overlay:removed', this.removeThisOverlay, this);

    this.model.set({
      src: opts.src,
      width: opts.width,
      height: opts.height,
      caption: opts.caption
    });
  },

  renderThis: function(){
    var html = this.contentTemplate(this.model.toJSON());
    return html;
  },

  hasRendered: function(){
    this.parent.preloadImageBlock(this.$el);
    this.resizeImage();
  },

  resizeImage: function(){
    imgSize({
      $el: this.$('img'),
      width: Aqualina.view.w,
      maxHeight: Aqualina.view.h - (2 * Aqualina.view.getFooterHeight()),
      midX: true,
      midY: true
    })
  },

  removeThisOverlay: function(){
    Aqualina.pubSub.off('app:resize', this.resizeImage);
  }
});

module.exports = OverlayImage;
