var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');
var videojs = require('video.js');

var _baseChild = require('./_baseChild');
var imgSize = require('../../utils/imgSize');
var breakpoints = require('../../utils/breakpoints');

var Designer = _baseChild.fullExtend({
  events: {
    'click .watchTheVideo': 'watchTheVideoHandler',
    'click .videoSplitCont .close': 'closeVideoHandler'
  },

  initThis: function(){
    Aqualina.pubSub.on('app:resize', this.resizeThisSection, this);
    this.pubSub.on('section:removed', this.removeThisSection, this);

    this.model.on({
      'change:percScrolled': this.updatePercScrolled
    }, this);

    this.updatePercScrolled(this.model, this.model.get('percScrolled'));
    this.resizeThisSection();
  },

  resizeThisSection: function(){
    var h = this.getChildHeight();
    var w = Aqualina.view.w;

    if(Aqualina.view.w <= breakpoints.mobile){
      h = Aqualina.view.w / 16 * 9;
      Velocity.hook(this.$('.textBlock'), 'translateX', 0 + '%');
    }

    this.$('.videoSplitCont').css({'width': w});

    imgSize({
      $el: this.$('.imageBlock img'),
      width: w * 0.505,
      minHeight: h,
      midX: true,
      midY: true
    });
  },

  updatePercScrolled: function(model, perc){
    var x = -100 * (1 - perc);
    var invX = 100 * (1 - perc);
    if(Aqualina.view.w <= breakpoints.mobile){
      x = 0;
      invX = 0;
    } else if(this.$el.hasClass('videoIn')){
      x = 100;
      invX = 0;
    }
    Velocity.hook(this.$('.textBlock'), 'translateX', x + '%');
    Velocity.hook(this.$('.textBlock .textBlockCont'), 'translateX', invX + '%');
  },

  setChildHeight: function(){
    var bool = true;
    if(Aqualina.view.w <= breakpoints.mobile){
      bool = false;
    }
    return bool;
  },

  trackPercScrolled: function(){
    return true;
  },

  watchTheVideoHandler: function(e){

    var videoID = this.$('video').attr('id');

    if(videoID){
      e.preventDefault();
      e.stopPropagation();

      videoID = videoID.split('_')[0];

      this.$el.addClass('videoIn');

      Velocity(this.$('.imageBlock'), {opacity: 0}, {
        duration: 400,
        display: 'none'
      });

      Velocity(this.$('.videoSplit'), {width: 100+'%'}, {
        duration: 900
      });

      Velocity(this.$('.textBlock'), {translateX: 100+'%'}, {
        duration: 900
      });

      videojs(videoID).play();
    }
  },

  closeVideoHandler: function(e){
    e.preventDefault();
    e.stopPropagation();

    var videoID = this.$('video').attr('id');

    if(videoID){
      e.preventDefault();
      e.stopPropagation();

      videoID = videoID.split('_')[0];

      this.$el.removeClass('videoIn');

      Velocity(this.$('.imageBlock'), {opacity: 1}, {
        duration: 400,
        delay: 400,
        display: 'block'
      });

      Velocity(this.$('.videoSplit'), {width: 50+'%'}, {
        duration: 900
      });

      Velocity(this.$('.textBlock'), {translateX: 0+'%'}, {
        duration: 900
      });

      videojs(videoID).pause();
    }
  },

  removeThisSection: function(){
    Aqualina.pubSub.off('app:resize', this.resizeThisSection);
  }

});

module.exports = Designer;
