var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var _basePage = require('./_basePage');
var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');

var Floorplans = _basePage.fullExtend({

  events: {
    'mouseenter .floorplan:not(.floorplanNotLinkable)': 'floorplanEnterHandler',
    'mouseleave .floorplan:not(.floorplanNotLinkable)': 'floorplanLeaveHandler'
  },

  initThis: function(){
    Aqualina.pubSub.on('app:resize', this.resizeThisPage, this);
    this.pubSub.on('page:removed', this.removeThisPage, this);

    this.resizeThisPage();
  },

  resizeThisPage: function(){
    this.$el.addClass('forceLayout');

    this.$('.floorplan').each(function(){
      var $el = $(this);
      var $building = $el.find('.buildingHover');

      if($building.length > 0){
        var _imgWidth = $building.find('.imageBlock').outerWidth();
        var _imgHeight = $building.find('.imageBlock').outerHeight();
        imgSize({
          $el: $building.find('img'),
          width: _imgWidth,
          maxHeight: _imgHeight,
          midX: true,
          midY: true
        });
      }
    });

    this.$el.removeClass('forceLayout');
  },

  floorplanEnterHandler: function(e){
    if(Aqualina.view.w <= breakpoints.tablet){ return; }
    var $e = $(e.currentTarget);
    this.activateFloorplan($e);
  },

  floorplanLeaveHandler: function(e){
    if(Aqualina.view.w <= breakpoints.tablet){ return; }
    this.deactivateFloorplan();
  },

  activateFloorplan: function($el){
    this.deactivateFloorplan();

    var $section = $el.closest('.floorplanCollection');

    var $otherSections = this.$el.find('.floorplanCollection').not($section);
    $otherSections.addClass('fadedOut');

    $otherFloorplans = $section.find('.floorplan').not($el);
    $otherFloorplans.addClass('fadedOut');

    this.fadeInHover($el);
  },

  deactivateFloorplan: function(){
    var that = this;

    this.$('.fadedOut').removeClass('fadedOut');

    this.$('.floorplan').each(function(){
      var $el = $(this);
      if($el.hasClass('fadingIn') || $el.hasClass('in')){
        that.fadeOutHover($el);
      }
    })
  },

  fadeInHover: function($el){
    var $building = $el.find('.buildingHover');

    if($building.length <= 0){ return; }

    $el.addClass('fading fadingIn')
      .removeClass('fadingOut');

    Velocity($building, 'stop');
    Velocity($building, {
      opacity: 1
    }, {
      display: 'block',
      duration: 600,
      queue: false,
      complete: function(el){
        $el.addClass('in')
          .removeClass('fading fadingIn');
      }
    });
  },

  fadeOutHover: function($el){
    var $building = $el.find('.buildingHover');

    if($building.length <= 0){ return; }

    $el.addClass('fading fadingOut')
      .removeClass('fadingIn in');

    Velocity($building, 'stop');
    Velocity($building, {
      opacity: 0
    }, {
      display: 'none',
      duration: 300,
      queue: false,
      complete: function(el){
        $el.removeClass('in')
          .removeClass('fading fadingOut');
      }
    });
  },

  removeThisPage: function(){
    Aqualina.pubSub.off('app:resize', this.resizeThisPage);
  }

});

module.exports = Floorplans;
