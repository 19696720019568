var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');

var baseGallery = require('./gallery_base');
var ImageView = require('./galleryImage_inline');

var breakpoints = require('../utils/breakpoints');

var GalleryInline = baseGallery.fullExtend({
    className: 'gallery galleryInline',

    initThis: function(){
        Aqualina.pubSub.on('app:resize', this.resize, this);
        this.resize();
    },

    resize: function(){
        this.$el.css({'width': '', 'height': ''});

        var w = this.$el.outerWidth();
        var h = this.$el.outerHeight();

        this.model.set({
            imgW: w,
            imgH: h
        });
        this.$el.css({'width': w, 'height': h});
    },

    removeViewThis: function(){
        Aqualina.pubSub.off('app:resize', this.resize, this);
    },

    renderThis: function(){
        this.changePost(this.model, this.model.get('cid'));
    },

    returnImageView: function(opts){
        return new ImageView(opts);
    }
});

module.exports = GalleryInline;
