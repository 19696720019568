var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
var Velocity = require('velocity-animate');

var breakpoints = require('../utils/breakpoints');
var imgSize = require('../utils/imgSize');

var HeaderView = Backbone.View.extend({
  el: 'header',

  events: {
    'click .menuToggle': 'menuToggleHandler'
  },

  initialize: function(){
    Aqualina.pubSub.on('app:scroll', this.scroll, this);
    this.pubSub = _.extend({}, Backbone.Events);

    this.model = new Backbone.Model({
      scrolled: false,
      menuOpen: false
    });

    this.model.on({
      'change:scrolled': this.scrolledToggle,
      'change:menuOpen': this.menuOpenToggle,
    }, this);

    this.listenTo(Aqualina.view.model, {
      'change:activeTitle': this.activeTitleUpdate
    }, this);

    Aqualina.pubSub.on('app:resize', this.resize, this);
    Aqualina.pubSub.on('app:resize:scroll', this.resize, this);
  },

  setHeader: function(){},

  resize: function(){
    if(Aqualina.view.w > breakpoints.laptop){
      this.model.set('menuOpen', false);
    }
    this.$('.menuOverlay').css({'width': Aqualina.view.w, 'height': Aqualina.view.h});
  },

  delegateHeader: function(path){
    this.model.set('menuOpen', false);
  },

  scroll: function(){
    var scrollPos = Aqualina.view.scrollPosition;

    var scrolled = false;
    if(Aqualina.view.view){
      var isFixedEl = false;

      if(Aqualina.view.view.children.length > 0 && Aqualina.view.view.children[0].target == 'LandingImage'){
        isFixedEl = true;
      }

      if(
        (isFixedEl && scrollPos > Aqualina.view.h - Aqualina.view.getFooterHeight() - this.returnHeight())
        || (!isFixedEl && scrollPos > this.returnHeight())
      ){
        scrolled = true;
      }
    }
    this.model.set('scrolled', scrolled);
  },

  returnHeight: function(){
    var h = 170;
    if(Aqualina.view.w <= breakpoints.mobile){
      h = 60;
    } else if(Aqualina.view.w <= breakpoints.laptop){
      h = 80;
    }
    return h;
  },

  scrolledToggle: function(model, scrolled){
      this.$el.toggleClass('scrolled', scrolled);
  },

  menuOpenToggle: function(model, open){
    this.$el.toggleClass('menuOpen', open);
    Aqualina.view.model.set('hasOverlay', open);

    Velocity(this.$('.bg'), {
      opacity: (open) ? 1 : 0
    }, {
      duration: (open) ? 800 : 600,
      display: (open) ? 'block' : 'none',
      queue: false
    });
  },

  menuToggleHandler: function(e){
    e.preventDefault();
    e.stopPropagation();

    this.model.set('menuOpen', !this.model.get('menuOpen'));
  },

  bgHandler: function(e){
    e.preventDefault();
    e.stopPropagation();

    this.model.set('menuOpen', false);
  }
});

module.exports = HeaderView;
