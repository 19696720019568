var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');
var Velocity = require('velocity-animate');

var Overlay = Backbone.View.extend({
  className: 'overlayLayer',
  attrName: 'base',
  template: _.template('<div class="closeBttn"></div><div class="tbl"><div class="tblCell"><div class="popContent"><%= content %></div></div></div>'),

  events: {
      'click': 'closePop',
      'click .closeBttn': 'closePop',
      'click .popContent': 'stopProp'
  },

  initialize: function(opts){
    this.model = new Backbone.Model();
    Aqualina.view.model.set('hasOverlay', true);

    this.pubSub = _.extend({}, Backbone.Events);

    Aqualina.pubSub.on('app:resize', this.resize, this);

    this.initThis(opts);
  },

  initThis: function(opts){
    return false;
  },

  setParent: function(that){
    this.parent = that;
  },

  render: function(){
    var content = this.renderThis();
    this.$el.html(this.template({content: content}));
    this.$el.attr('overlay-type', this.attrName);
    return this;
  },

  resize: function(){},

  renderThis: function(){
    return '';
  },

  hasRendered: function(){},

  animateIn: function(){
    this.$el.addClass('unhide');

    this.hasRendered();
    this.resize();

    Velocity(this.$el, {
      'opacity': [1, 0]
    }, {
      duration: 800,
      display: 'block'
    });
  },

  animateOut: function(force){
    var that = this;
    var t = 400;

    if(force){
      t = 0;
    }

    Velocity(this.$el, {
      'opacity': [0, 1]
    }, {
      duration: t,
      display: 'none',
      complete: function(){
          that.remove();
      }
    });
  },

  remove: function(){
    this.pubSub.trigger('overlay:remove');

    this.undelegateEvents();
    Aqualina.view.model.set('hasOverlay', false);
    Aqualina.pubSub.off('app:resize', this.resize, this);
    this.$el.remove();
    this.parent.overlay = null;
  },

  stopProp: function(e){
    e.stopPropagation();
  },

  closePop: function(e){
    e.preventDefault();
    e.stopPropagation();

    this.animateOut();
  }
});

module.exports = Overlay;
