var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var imgSize = require('../utils/imgSize');

var overlayBase = require('./overlayBase');

var OverlayBrochure = overlayBase.fullExtend({

  attrName: 'brochure',

  contentTemplate: _.template('<div class="popBox">\
    <div class="closeBttn"></div>\
    <h3><%= title %></h3>\
    <%= brochureHtml %>\
  </div>'),

  initThis: function(opts){
    this.model.set({
      title: 'Download Brochure',
      brochureHtml: opts.brochureHtml
    });
  },

  renderThis: function(){
    var html = this.contentTemplate(this.model.toJSON());
    return html;
  }
});

module.exports = OverlayBrochure;
