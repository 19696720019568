var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var Gallery = require('./Gallery');
var imgSize = require('../../utils/imgSize');
var breakpoints = require('../../utils/breakpoints');

var GallerySlideIn = Gallery.fullExtend({

  initThisThis: function(){
    this.model.on({
      'change:percScrolled': this.updatePercScrolled
    }, this);

    this.updatePercScrolled(this.model, this.model.get('percScrolled'));
  },

  trackPercScrolled: function(){
    return true;
  },

  updatePercScrolled: function(model, perc){
    var x = 50 * (1 - perc);
    var invX = 50 * (1 - perc) * -1;
    if(Aqualina.view.w <= breakpoints.mobile){
      x = 0;
      invX = 0;
    }

    Velocity.hook(this.$('.galleryContainer'), 'translateX', x + '%');
    Velocity.hook(this.$('.galleryInlineBuild'), 'translateX', invX + '%');
  },

});

module.exports = GallerySlideIn;
