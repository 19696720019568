var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var _baseChild = require('./_baseChild');
var imgSize = require('../../utils/imgSize');

var LandingImage = _baseChild.fullExtend({
  events: {
    'click .arrowDown': 'arrowDownHandler'
  },

  initThis: function(){
    Aqualina.pubSub.on('app:resize', this.resizeThisSection, this);
    this.pubSub.on('section:removed', this.removeThisSection, this);

    this.resizeThisSection();

    this.initThisThis();
  },

  initThisThis: function(){},

  setChildHeight: function(){
    return true;
  },

  limitHeightToWindow: function(){
    return true;
  },

  resizeThisSection: function(){
    var h = Math.min(Aqualina.view.getVisibleHeight(), this.getChildHeight());

    this.$('.fixedEl')
      .css({'width': Aqualina.view.w, 'height': h})
      .toggleClass('nextElVisible', h <= Aqualina.view.h * 0.5);

    imgSize({
      $el: this.$('.fixedEl .imageBlock img'),
      width: Aqualina.view.w,
      minHeight: h,
      midX: true,
      midY: true
    });
  },

  getChildRatio: function(){
    var ratio;
    if(this.$el.hasClass('halfWindowHeight')){
      ratio = 5 / 16;
    } else {
      ratio = 9 / 16;
    }
    return ratio;
  },

  arrowDownHandler: function(e){
    e.preventDefault();
    e.stopPropagation();

    Aqualina.view.scrollTo(this.$el.next('section'));
  },

  removeThisSection: function(){
    Aqualina.pubSub.off('app:resize', this.resizeThisSection);
  }

});

module.exports = LandingImage;
