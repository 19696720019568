var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');
var Velocity = require('velocity-animate');
var jQBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');

$.bridget('isotope', Isotope);

var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');

var Grid = Backbone.View.extend({
  initialize: function(){
    Aqualina.pubSub.on('app:resize', this.resize, this);

    this.$el.isotope({
      itemSelector: '.gridBlock',
      masonry: {
        columnWidth: 50
      },
      transitionDuration: 0
    });

    this.resize();
  },

  resize: function(){
    var gridW = Aqualina.view.w - (Aqualina.view.getMargin() * 2);
    var columnCount = this.calculateColumnCount();
    var columnWidth = this.calculateColumnWidth(gridW, columnCount);
    var margin = this.getMargin();

    this.$('.gridBlock').each(function(){
      var $el = $(this);

      var _w = columnWidth;

      if($el.find('.videoBlockExpand').length > 0){

        var _minHeight = _w / 16 * 9;

        var dimensions = imgSize({
          $el: $el.find('img'),
          width: _w + 2,
          minHeight: _minHeight,
          midX: true,
          midY: true
        });

        $el.find('.videoBlockExpand').css({'width': _w, 'height': _minHeight});

      } else {

        var dimensions = imgSize({
          $el: $el.find('img'),
          width: _w + 2,
          midX: true,
          midY: true
        });

        $el.find('.imageBlock').css({'width': _w, 'height': dimensions.height - 2});
      }

      $el.css({'width': _w});
    });

    this.$el.css({'width': gridW + margin}).isotope({
      masonry: {
        columnWidth: columnWidth + margin
      }
    });
  },

  getMargin: function(){
    var m = 40;
    if(Aqualina.view.w <= breakpoints.mobile){
      m = 20;
    } else if(Aqualina.view.w <= breakpoints.tabletPortrait){
      m = 30;
    }
    return m;
  },

  calculateColumnCount: function(){
    var colCount = 3;
    if(Aqualina.view.w <= breakpoints.mobile){
      colCount = 1;
    } else if(Aqualina.view.w <= breakpoints.tabletPortrait){
      colCount = 2;
    }
    return colCount;
  },

  calculateColumnWidth: function(width, count){
    var colWidth = (width-(this.getMargin()*(count-1)))/count;
    return colWidth;
  },

  removeView: function(){
    Aqualina.pubSub.off('app:resize', this.resize);

    this.undelegateEvents();
    this.$el.remove();

    this.remove();
  }
});

module.exports = Grid;
