var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;
Backbone.View.fullExtend = require('../utils/Backbone.fullExtend');

var _basePage = require('./_basePage');

var Home = _basePage.fullExtend({

  isHome: function(){ return true; },

  initThis: function(){
    this.pubSub.on('page:removed', this.removeThisPage, this);

    if(this.videos && this.videos.length > 0){
      var video = this.videos[0];
      this.listenToOnce(video.pubSub, 'video:end', this.onVideoEnd);
    }
  },

  onVideoEnd: function(){
    var $section = this.$('section:eq(1)');
    if($section.length <= 0){ return; }

    if(Aqualina.view.scrollPosition < $section.offset().top){
      Aqualina.view.scrollTo($section);
    }
  },

  removeThisPage: function(){
    Aqualina.pubSub.off('app:resize', this.resizeThisPage);
  }

});

module.exports = Home;
