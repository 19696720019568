var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var imgSize = require('../utils/imgSize');

var overlayBase = require('./overlayBase');
var VideoView = require('./video');

var OverlayVideo = overlayBase.fullExtend({

  attrName: 'video',

  contentTemplate: _.template('<div class="videoBlock videoBlockOverlay"><%= videoHtml %></div>'),

  initThis: function(opts){
    this.pubSub.on('overlay:removed', this.removeThisOverlay, this);
    this.model.set({
      videoHtml: opts.videoHtml
    });
  },

  renderThis: function(){
    var html = this.contentTemplate(this.model.toJSON());
    return html;
  },

  hasRendered: function(){
    //this.$('video').attr('autoplay', 'autoplay');
    this.video = new VideoView({
      el: this.$('.videoBlock'),
      autoplay: true
    });
  },

  removeThisOverlay: function(){
    if(this.video){
      this.video.removeView();
      this.video = null;
    }
  }
});

module.exports = OverlayVideo;
