var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var _baseChild = require('./_baseChild');
var imgSize = require('../../utils/imgSize');
var breakpoints = require('../../utils/breakpoints');

var HomepageBlocks = _baseChild.fullExtend({

  initThis: function(){
    Aqualina.pubSub.on('app:resize', this.resizeThisSection, this);
    this.pubSub.on('section:removed', this.removeThisSection, this);
  },

  resizeThisSection: function(){
    var blockWidth = this.$('.block:eq(0)').outerWidth();
    var margin = Aqualina.view.getMargin();

    this.$('.blockCol:eq(1)').each(function(){
      var _h = blockWidth;
      if(Aqualina.view.w <= breakpoints.mobilePortrait){
        _h = '';
      }
      $('.block', this).css({'height': _h});
    });

    this.$('.blockCol:eq(0)').each(function(){
      $('.block', this).each(function(i){
        var _h;
        if(Aqualina.view.w <= breakpoints.mobilePortrait){
          _h = '';
        } else {
          if(i == 0){
            _h = blockWidth + (blockWidth * 0.5);
          } else {
            _h = blockWidth * 0.5;
          }
        }
        $(this).css({'height': _h});
      });
    });

    this.$('.blockCol').each(function(){
      $('.block', this).each(function(){
        var _w = $(this).outerWidth();
        var _h = $(this).outerHeight();

        if($('img', this).length > 0){
          imgSize({
            $el: $('img', this),
            width: _w,
            minHeight: _h,
            midX: true,
            midY: true
          });
        }
      });
    })
  },

  removeThisSection: function(){
    Aqualina.pubSub.off('app:resize', this.resizeThisSection);
  }

});

module.exports = HomepageBlocks;
