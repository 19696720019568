var $ = jQuery = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var _basePage = require('./_basePage');
var imgSize = require('../utils/imgSize');
var breakpoints = require('../utils/breakpoints');

var Floorplan = _basePage.fullExtend({

  events: {
    'click .toSlideshowScroll': 'toSlideshowScrollHandler'
  },

  initThis: function(){
    Aqualina.pubSub.on('app:resize', this.resizeThisPage, this);
    this.pubSub.on('page:removed', this.removeThisPage, this);

    this.resizeThisPage();
  },

  resizeThisPage: function(){},

  toSlideshowScrollHandler: function(e){
    e.preventDefault();
    e.stopPropagation();

    var $e = $(e.currentTarget);

    var target = $e.attr('slideshow-target');

    var $target = this.$('section[slideshow-target="'+target+'"]');
    Aqualina.view.scrollTo($target);
  },

  removeThisPage: function(){
    Aqualina.pubSub.off('app:resize', this.resizeThisPage);
  }

});

module.exports = Floorplan;
